/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import "array-flat-polyfill";
import "whatwg-fetch";
import "url-search-params-polyfill";
global.set = require("es6-set");
